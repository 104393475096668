import { Row, Col, Button } from 'react-bootstrap';
import ServitechLogo from './svg/servitech-logo.svg'

import './styles.css'


export default function TodoComponent(props) {
    const handleGoBack = () => {
        window.history.back();
    }

    return (
        <div className='header'>
            <Row className='row-header'>
                <Col xs={2} s={2} md={2} lg={2} xl={2}>
                    <img alt="ServiTech" className="logo" src={ServitechLogo} />
                </Col>
                <Col xs={{ span: 2, offset: 10 }} s={{ span: 2, offset: 10 }} md={{ span: 2, offset: 10 }} lg={{ span: 2, offset: 10 }} xl={{ span: 2, offset: 10 }}>
                    <Button onClick={handleGoBack} className='back-link'><b>Back</b></Button>
                </Col>
            </Row>
            <Row className='page-header'>
                <p ><b>Supplies Order Form</b></p>
            </Row>
        </div>
    )
}


