import { Col, Form, Row } from 'react-bootstrap';


import './styles.css'

const labs = [
    "Dodge City",
    "Amarillo",
    "Hastings"
]

export default function TodoComponent(props) {
    const {
        customerInformation,
        handleUpdateAccountNumber,
        handleUpdateContactName,
        handleUpdateEmailName,
        handleUpdateAddress,
        handleUpdateCity,
        handleUpdateState,
        handleUpdateZipCode,
        handleUpdatePhoneNumber,
        handleUpdateLabLocation,
        availableAccounts,
        accountAddress,
        userAddress,
        handleRadioAddress
    } = props;


    return (
        <>
            <Col xs={12} s={12} md={4} lg={4} xl={4} className='shipping-col'>
                <Row className='radio-btn-row'/>
                <Row><span className='info-labels'>Account To Be Billed</span></Row>
                <Row className='shipping-row'>
                    <Form.Select
                        as="select"
                        size="sm"
                        className='shipping-input'
                        value={customerInformation.accountNumber}
                        onChange={handleUpdateAccountNumber}
                    >
                        <option value="" disabled>--Please Select An Account--</option>
                        {availableAccounts.length > 0 && availableAccounts.map((account, index) =>
                            <option value={account.AccountID} key={index}>{account.AccountName} &#40;{account.AccountID}&#41;</option>
                        )}
                        {availableAccounts.length === 0 &&
                            <option value="No Accounts Available">No Accounts Available</option>
                        }
                    </Form.Select>
                </Row>

                <Row><span className='info-labels'>Contact Name</span></Row>
                <Row className='shipping-row'>
                    <Form.Control
                        type="text"
                        as="input"
                        size="sm"
                        value={customerInformation.fullName}
                        className='shipping-input'
                        onChange={handleUpdateContactName}
                    />
                </Row>

                <Row><span className='info-labels'>Email Address</span></Row>
                <Row className='shipping-row'>
                    <Form.Control
                        type="text"
                        as="input"
                        size="sm"
                        className='shipping-input'
                        value={customerInformation.emailName}
                        onChange={handleUpdateEmailName}
                    />
                </Row>

                <Row><span className='info-labels'>Phone</span></Row>
                <Row className='shipping-row'>
                    <Form.Control
                        type="text"
                        as="input"
                        size="sm"
                        value={customerInformation.phoneNumber}
                        className='shipping-input'
                        onChange={handleUpdatePhoneNumber}
                    />
                </Row>

                <Row><span className='info-labels'>Lab Location</span></Row>
                <Row className='shipping-row'>
                    <Form.Select
                        as="select"
                        size="sm"
                        className='shipping-input'
                        value={customerInformation.labLocation}
                        onChange={handleUpdateLabLocation}
                    >
                        <option value="" disabled>--Please Select A Lab Location--</option>
                        {labs.map((lab, index) =>
                            <option value={lab} key={index}>{lab}</option>
                        )}
                    </Form.Select>
                </Row>
            </Col>
            {/* hardCoded account address */}
            <Col xs={12} s={12} md={4} lg={4} xl={4} className='shipping-col'>
                <Row className='radio-btn-row'>
                    {customerInformation && (customerInformation.accountNumber === "" || customerInformation.accountNumber === "No Accounts Available") &&
                        <Form.Check
                            inline
                            disabled
                            label="Ship To: Account Address"
                            type='radio'
                            id="accountAd"
                        />
                    }
                    {customerInformation && (customerInformation.accountNumber !== "" && customerInformation.accountNumber !== "No Accounts Available") &&
                        <Form.Check
                            inline
                            label="Ship To: Account Address"
                            name="group1"
                            type='radio'
                            id="accountAd"
                            onChange={(e) => { handleRadioAddress(e) }}
                        />
                    }
                </Row>
                <Row><span className='info-labels'>Address</span></Row>
                <Row className='shipping-row'>
                    <Form.Control
                        size="sm"
                        placeholder={accountAddress.address}
                        className='shipping-input'
                        disabled
                    />
                </Row>

                <Row><span className='info-labels'>City</span></Row>
                <Row className='shipping-row'>
                    <Form.Control
                        size="sm"
                        value={accountAddress.city}
                        className='shipping-input'
                        disabled
                    />
                </Row>

                <Row><span className='info-labels'>State</span></Row>
                <Row className='shipping-row'>
                    <Form.Control
                        size="sm"
                        value={accountAddress.state}
                        className='shipping-input'
                        disabled
                    />
                </Row>

                <Row><span className='info-labels'>Zip Code</span></Row>
                <Row className='shipping-row'>
                    <Form.Control
                        size="sm"
                        value={accountAddress.zipCode}
                        className='shipping-input'
                        disabled
                    />
                </Row>
            </Col>
            {/*User profile address can be edited*/}
            <Col xs={12} s={12} md={4} lg={4} xl={4} className='shipping-col'>
                <Row className='radio-btn-row'>
                    <Form.Check
                        inline
                        label="Ship To: Other"
                        name="group1"
                        type='radio'
                        id="userAd"
                        onChange={(e) => { handleRadioAddress(e) }}
                    />
                </Row>
                <Row><span className='info-labels'>Address</span></Row>
                <Row className='shipping-row'>
                    <Form.Control
                        type="text"
                        as="input"
                        size="sm"
                        value={userAddress.address}
                        className='shipping-input'
                        onChange={handleUpdateAddress}
                    />
                </Row>

                <Row><span className='info-labels'>City</span></Row>
                <Row className='shipping-row'>
                    <Form.Control
                        type="text"
                        as="input"
                        size="sm"
                        value={userAddress.city}
                        className='shipping-input'
                        onChange={handleUpdateCity}
                    />
                </Row>

                <Row><span className='info-labels'>State</span></Row>
                <Row className='shipping-row'>
                    <Form.Control
                        type="text"
                        as="input"
                        size="sm"
                        value={userAddress.state}
                        className='shipping-input'
                        onChange={handleUpdateState}
                    />
                </Row>

                <Row><span className='info-labels'>Zip Code</span></Row>
                <Row className='shipping-row'>
                    <Form.Control
                        type="text"
                        as="input"
                        size="sm"
                        value={userAddress.zipCode}
                        className='shipping-input'
                        onChange={handleUpdateZipCode}
                    />
                </Row>
            </Col>
        </>
    )
}


