import { Modal } from 'react-bootstrap';

const SuccessModal = (props) => {
    const {
        isOpen,
        setModalOpen,
    } = props;

    const handleOnHide = () => {
        setModalOpen(!isOpen)
    }

    return (
        <Modal
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isOpen}
            onHide={handleOnHide}
        >
            <div className='missing-data-modal'>
                <Modal.Body className='missing-data-modal-body'>
                    <form>
                        We will begin processing your order. Thank you!
                    </form>
                </Modal.Body>
            </div>
        </Modal>
    )
}

export default SuccessModal;