import { Modal, Button } from 'react-bootstrap';

const MissingDataModal = (props) => {
    const {
        isOpen,
        setModalOpen,
        missingData
    } = props;

    const handleOnHide = () => {
        setModalOpen(!isOpen)
    }

    return (
        <Modal
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isOpen}
            onHide={handleOnHide}
        >
            <div className='missing-data-modal'>
                <Modal.Body className='missing-data-modal-body'>
                    <form>
                        <p>Could not complete your order. You are missing the following infomation:</p>
                        {missingData && missingData.map((missing, index) =>
                            <li key={index} style={{ marginLeft: "1vw" }}>
                                {missing}
                            </li>
                        )}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={e => handleOnHide(e)}
                    >
                        Return To Order
                    </Button>
                </Modal.Footer>
            </div>
        </Modal>
    )
}

export default MissingDataModal;