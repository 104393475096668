import { useEffect, useState } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';


import './styles.css'

export default function TodoComponent(props) {
    const {
        supplies,
        handleAddSuppliesForOrder
    } = props;

    const [suppliesToShow, setSTS] = useState([{
        supplyCategoryID: "",
        supplyCategory: "",
        order: "",
        header: "",
        footer: "",
        supplies: [{
            supplyID: "",
            supplyName: "",
            supplyDescription: "",
            emailName: "",
            order: "",
            supplyCategoryID: ""
        }]
    }])

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.which > 57 || e.which === 32) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        let newArr = [];
        supplies.map((x) => {
            if (x.supplyCategory !== 'Soil Probe Equipment' && x.supplyCategory !== 'Hay Probe Equipment' && x.supplyCategory !== 'Other Equipment') {
                console.log(x);
                console.log('here');
                newArr.push(x);
            }
        })
        setSTS(newArr)
    }, [supplies])

    return (
        <>
            {suppliesToShow.map((item, index) => 
                <Col xs={12} s={12} md={6} lg={6} xl={6} className="card-col" key={index}>
                    <Row>
                        <span className="supply-block-subheads">
                            &nbsp;&nbsp;&nbsp;<b>{item.supplyCategory}</b>
                        </span>
                        <Card className="supplies-card">
                            {item.supplies.map((card, indexx) =>
                                <Row className='card-supplies-row' key={indexx}>
                                    <Col xs={3} s={3} md={3} lg={2} xl={2}>
                                        <Form.Control
                                            className="quantity-input"
                                            type="input"
                                            min="0"
                                            as="input"
                                            placeholder="0"
                                            size="sm"
                                            style={{ fontWeight: "bold", fontSize: "18px" }}
                                            onKeyPress={preventMinus}
                                            onChange={e => handleAddSuppliesForOrder(e, card, item.supplyCategory)}
                                        // onWheel={(e) => e.target.blur()} //works for type="number"
                                        />
                                    </Col>
                                    <Col xs={9} s={9} md={9} lg={10} xl={10} className="supplies-descriptions">
                                        <p className='order-items'>
                                            {card.supplyName} &nbsp;
                                            {card.supplyDescription}
                                        </p>
                                    </Col>
                                    <p></p>
                                </Row>
                            )}
                            {item.supplyCategory === 'Water Sampling Supplies' &&
                                <Row className="wtr-sup-mssg-row">
                                    <span className='wtr-sup-mssg'>
                                        <b>Call Lab For Special Request</b>
                                    </span>
                                </Row>
                            }
                        </Card>
                    </Row>
                </Col>
            )}
        </>
    )

}